import Vue from 'vue'
import axios from 'axios'

const api = axios.create({
  baseURL: CONFIG.apiUrl,
})

api.interceptors.response.use(
  response => response.data,
  error => {
    if (!error.response) return Promise.reject(error)
    if (!error.response.data) return Promise.reject(error)
    return Promise.reject(error.response.data)
  },
)

Vue.prototype.$api = api

export default api
