import Vue from 'vue'

Vue.filter('toLocale', (val, maxDigits = 2) => val.toLocaleString(undefined, {
  minimumFractionDigits: 0,
  maximumFractionDigits: maxDigits,
}))

Vue.filter('toCurrency', (val, currency = 'EUR', maxDigits = 2) => val.toLocaleString(undefined, {
  minimumFractionDigits: 2,
  maximumFractionDigits: maxDigits,
  style: 'currency',
  currency,
}))
