import Vue from 'vue'
import Modal from '@/components/Modal'
import EventBus from './eventbus'

// get all modals
const files = require.context('@/modals', true, /^(?!.*(views|components)).*\.vue$/i, 'lazy')

const modals = files.keys().reduce((acc, fileName) => {
  const componentName = fileName.split('/').pop().replace(/\.\w+$/, '')
  acc[componentName] = fileName.substr(2)
  return acc
}, {})

const toOpen = []
let parentVm = null

export const openModals = []

function spawn(propsData, id) {
  if (!parentVm) throw new Error('No parent vm found')

  const ModalComponent = Vue.extend(Modal)
  return new ModalComponent({
    el: document.createElement('div'),
    propsData,
    parent: parentVm,
    onClose() {
      openModals.splice(openModals.findIndex(i => i.id === id), 1)
    },
  })
}

export function open(type, options = {}, payload = {}) {
  if (!type) throw new Error('No modal specified')

  const id = Math.random().toString(36).substring(2, 9)
  const modal = modals[type]

  try {
    const comp = spawn({ options, payload, modal }, id)
    openModals.push({ id, type })

    return comp
  } catch (error) {
    if (error.message === 'No parent vm found') {
      toOpen.push([type, options, payload])

      return null
    }

    throw error
  }
}

EventBus.$once('app.created', vm => {
  parentVm = vm

  // // give vuetify some time to boot up
  // setTimeout(() => {
  for (const pendingModal of toOpen) {
    open(...pendingModal)
  }
  // }, 500)
})

Vue.prototype.$modal = {
  open,
}

export default true
