<template>
  <v-app>
    <v-app-bar
      v-bind="navbarProps"
      class="navbar" flat
      app
    >
      <v-tabs
        class="d-none d-sm-block" color="red" centered optional
        hide-slider
      >
        <v-tab :ripple="false" @click="$modal.open('order', {maxWidth: 400})">
          Jetzt kaufen
        </v-tab>
        <v-tab :ripple="false" to="/" exact>
          <span class="text-h6 title-font">Der Psychonaut</span>
        </v-tab>
        <v-tab :ripple="false" @click="$modal.open('leseproben', {maxWidth: 400})">
          Leseprobe
        </v-tab>
      </v-tabs>

      <router-link to="/" tag="h4" class="full-width text-center text-h5 title-font d-sm-none">
        Der Psychonaut
      </router-link>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <v-footer color="#030303" class="py-8">
      <v-container class="text-center">
        <v-row justify="center">
          <v-col cols="12">
            <h3 class="text-h4 mb-2 title-font">
              Der Psychonaut
            </h3>
            <h4 class="text-body-1">
              Ein Buch von Francis Lowie
            </h4>
          </v-col>

          <v-col cols="auto">
            <router-link to="/impressum" class="link">
              Impressum
            </router-link>
          </v-col>
          <v-col cols="auto">
            <a href="/documents/agb.pdf" class="link">
              AGB
            </a>
          </v-col>
          <v-col cols="auto">
            <a href="/documents/datenschutz.pdf" class="link">
              Datenschutz
            </a>
          </v-col>
          <v-col cols="auto">
            <a class="link" @click="$modal.open('admin', { maxWidth: 400 })">
              Admin
            </a>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  computed: {
    navbarProps() {
      if (this.$route.name === 'Home') {
        return {
          invertedScroll: true,
          hideOnScroll: true,
          scrollThreshold: 500,
        }
      }

      return {}
    },
  },
}
</script>

<style lang="scss">
body #app {
  background: black;
}

a.link {
  text-decoration: none;
}

#app .title-font {
  font-family: "Westsac", serif !important;
  color: rgb(194, 6, 0);
  text-shadow: 0 0 40px rgb(175, 6, 6), 0 0 120px rgba(131, 2, 2, 0.4), 0 0 4px rgb(138, 10, 10);
}

</style>

<style lang="scss" scoped>
.v-sheet.navbar {
  background: rgba(black, .4) !important;
  backdrop-filter: blur(16px);
}
</style>
