<template>
  <div class="page-content">
    <div class="parallax-box">
      <video
        class="parallax backdrop-video"
        data-speed="-30"
        muted autoplay loop
      >
        <source src="/videos/black_hole.mp4" type="video/mp4">
      </video>

      <div class="parallax d-flex flex-column align-center justify-center" data-speed="-35" data-scale="[1.2, 0.8]">
        <div>
          <h2 class="title-font mb-md-1 text-uppercase font-weight-bold text-h5 text-sm-h4 text-md-h3 pl-12">
            Der
          </h2>
          <h1 class="title-font text-uppercase font-weight-bold text-h4 text-sm-h3 text-md-h2">
            Psychonaut
          </h1>
        </div>
      </div>

      <v-img class="parallax" data-speed="-20" src="@/assets/layers/1.webp" />
      <v-img class="parallax" data-speed="-20" src="@/assets/layers/2.webp" />
      <v-img class="parallax" data-speed="-12" src="@/assets/layers/3.webp" />

      <v-img class="parallax" data-speed="-12" src="@/assets/layers/4.webp" />
      <v-img class="parallax" data-speed="-20" src="@/assets/layers/5.webp" />
      <v-img class="parallax" data-speed="-5" src="@/assets/layers/6.webp" />

      <h4 class="parallax pt-8 text-h6 text--secondary text-center">
        Francis Lowie
      </h4>

      <div id="anchor" />
    </div>

    <v-row justify="center" class="mb-6">
      <v-col cols="auto">
        <v-btn
          color="primary" rounded x-large
          @click="$modal.open('order', {maxWidth: 400})"
        >
          Jetzt kaufen
        </v-btn>
      </v-col>

      <v-col cols="auto">
        <v-btn
          color="grey darken-4"
          rounded x-large
          @click="$modal.open('leseproben', {maxWidth: 400})"
        >
          Kostenlose Leseprobe
        </v-btn>
      </v-col>
    </v-row>

    <v-row>
      <v-col
        cols="12"
        lg="8" offset-lg="2"
        xl="6" offset-xl="3"
      >
        <v-container class="px-8">
          <v-row>
            <v-col cols="12" class="text-center">
              <p class="text-h6 text-sm-h5 text--text font-weight-regular font-merriweather mb-6">
                Frank Wagner, der Protagonist der Handlung, experimentiert mit psychoaktiven Drogen, wodurch er
                zunächst positive Erlebnisse hat. Die Erfahrungen verkehren sich aber bereits nach kurzer Zeit in das
                genaue Gegenteil.
              </p>
              <p class="text-h6 text-sm-h5 font-weight-bold mb-0 font-merriweather">
                Hat er das Tor zur Hölle aufgestoßen in einer anderen Dimension?
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-col>

      <v-col
        cols="12"
        lg="8" offset-lg="2"
        xl="6" offset-xl="3"
      >
        <v-container class="px-8">
          <v-row align="center">
            <v-col cols="12">
              <h4 class="title-font text-h4 text-sm-h3 red--text font-weight-bold mb-4 text-center">
                Auszug aus Kapitel 10
              </h4>
            </v-col>

            <v-col cols="12" class="text-justify text--text">
              <p class="text-body-1 text-sm-h6 font-weight-regular font-merriweather">
                Die Erkenntnis brachte ihn derart aus der Fassung, dass er gewaltsam die Augen aufriss. Die Situation ließ ihm keine Zeit für
                Überlegungen, ob sein Unterbewusstsein ihm eine Rückführung an den Beginn seines Lebens offenbart hatte. Menschen mit
                Nahtoderfahrungen hatten immer wieder behauptet, das gesamte Leben sei nochmal im Zeitraffer als Film vor ihnen
                abgelaufen. Da er einen Sturz aus dieser Höhe unter normalen Umständen unmöglich überlebt haben konnte, wäre es
                wenigstens teilweise eine Bestätigung derartiger Aussagen gewesen. Aber selbst unter dem Aspekt, solche Behauptungen als
                verlässlich einzustufen, war hier alles anders.
              </p>

              <p class="text-body-1 text-sm-h6 font-weight-regular font-merriweather">
                Zwar hatte er an diesem Ort einen stofflichen Körper. Er war zumindest bisher
                der Meinung, sich nicht als Geist in der absurden Welt zu befinden. Aber bereits die Tatsache, jenes Umfeld wahrzunehmen,
                welches er kurz vor seinem Aufschlag für den Bruchteil von Sekunden erkannt hatte, ließ ihn vermuten, nicht tot zu sein,
                vorausgesetzt er war nicht schon bei Beginn seines Experimentes gestorben und existierte hier tatsächlich in einer anderen
                Dimension. Wenn er sich wirklich in einer anderen Dimension befand, konnten hier durchaus andere Gesetzmäßigkeiten
                herrschen. Vielleicht befand er sich in einem Zwischenreich, gefangen zwischen Leben und Tod? Als Individuum lebte er nicht
                mehr, war aber auch kein Geist. Über höher gelagerte Dimensionen hatten die Menschen keine zuverlässigen Erkenntnisse. Das
                meiste Wissen war spekulativ, da beispielsweise Spukphänomene spontan auftraten und Personen, die solche Ereignisse
                erforschten, sie nur aus ihren eigenen Dimensionen bewerten konnten und keinen Einfluss auf den Verlauf der Geschehnisse
                hatten. Wenn Franky tatsächlich tot war und es einen Himmel gab, dann befand er sich definitiv nicht dort, sondern eher an
                einer Stätte, die der Hölle wesentlich mehr glich. Dafür sorgte schon der abartige Gestank, den er wahrnahm, allerdings war
                der Umstand noch das kleinste Übel des Horrorszenarios, das sich ihm darstellte.
              </p>

              <p class="text-body-1 text-sm-h6 font-weight-regular font-merriweather">
                Noch völlig benommen von seinem Sturz, quälten ihn als Erstes unsagbare Schmerzen am ganzen Körper. Er hatte den Eindruck, sämtliche Knochen seines Skelettes
                waren mehrfach gebrochen und alle Organe waren durch den Aufprall zerplatzt. Er hatte den Eindruck, sie wurden nur noch
                von seiner Hülle zusammengehalten. Zunächst verharrte er noch reglos auf einem eher schwammigen und glitschigen
                Untergrund. Erst als er zu erkennen begann, um was es sich dabei handelte, konnte ihn nichts mehr in seiner Lethargie halten.
                Franky lag auf hunderten, wenn nicht sogar auf tausenden menschlichen Kadavern, die sich im Verwesungsprozess befanden.
                Die Leiber waren teilweise nackt und zum Teil bekleidet. Manche waren zerfetzt oder es fehlten ihnen Gliedmaßen. Bei
                einigen war der Prozess der Fäulnis weiter vorangeschritten wie bei anderen. Wäre sein gesamter Sinneseindruck nicht von
                Entsetzten und Grauen beherrscht worden, hätte er sich sicherlich gefragt, ob diese Körper auch durch den gewaltigen Schacht
                herab gestürzt waren und wenn es so war, wieso lebte er noch und die anderen nicht mehr? Durch seinen schockartigen
                Zustand stellte er sich diese Frage jedoch erst gar nicht sondern zog seine Arme an und stemmte sich auf, wobei er sofort
                wieder mit einer Hand abrutschte, da der widerliche Untergrund instabil war und der Arm, dessen Schultergelenk durch den
                Aufprall auf einem der Pfeiler zerstört worden war, den Befehlen seines Gehirnes nur mäßig folgte. Dadurch bewegte er sich
                jedoch noch hektischer, was ihm unsagbare Schmerzen bereitete, doch der Wunsch sich von den verwesenden Leichen zu
                entfernen, dominierte sein gesamtes Denken und Handeln. Seine Knochen knirschten seltsam, als er sich erhob. Noch bevor er
                sich ganz aufgerichtet hatte, fiel er erneut. Dabei rutschte und kullerte er von dem Berg von toten Leibern. Mit
                schmerzverzerrtem Gesicht richtete er sich immer wieder auf, um sich so rasch wie möglich von dem widerlichen Ort zu
                entfernen. Als er endlich unten war, entfernte er sich fluchtartig einige Meter von den Toten um sich dann heftig zu übergeben.
                Ekel und Gestank übertrumpften im Moment sogar noch seine Schmerzen, allerdings gab sein Mageninhalt nicht viel her. Er
                würgte hauptsächlich Galle hervor, dennoch wollte der Brechreiz zunächst nicht abklingen, da die eben gemachten
                Erfahrungen zu krass für Franky waren, um sie sofort verarbeiten zu können.
              </p>

              <p class="text-body-1 text-sm-h6 font-weight-regular font-merriweather">
                Mit jeder Sekunde, die verging, begann sich seine Wahrnehmung zu schärfen und er nahm sein Umfeld immer bewusster
                wahr. Das Grauen und Entsetzen ließen ihn seine Schmerzen und den Durst im Moment vergessen. Er konnte zunächst nicht
                glauben, was er erkannte und fragte sich, ob er tatsächlich zu sich gekommen war oder er sich noch in einem traumatischen
                Zustand befand...
              </p>
            </v-col>
          </v-row>
        </v-container>
      </v-col>

      <v-col cols="12" class="mb-16">
        <v-row justify="center">
          <v-col cols="auto">
            <v-btn
              color="primary" rounded x-large
              @click="$modal.open('order', {maxWidth: 400})"
            >
              Jetzt kaufen
            </v-btn>
          </v-col>

          <v-col cols="auto">
            <v-btn
              color="grey darken-4"
              rounded x-large
              @click="$modal.open('leseproben', {maxWidth: 400})"
            >
              Kostenlose Leseprobe
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ParallaxController } from 'parallax-controller'

export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      controller: null,
    }
  },
  mounted() {
    this.setupParallax()
  },
  updated() {
    this.setupParallax()
  },
  methods: {
    setupParallax() {
      this.controller?.destroy()

      this.controller = ParallaxController.init({})

      const parallaxImages = document.querySelectorAll('.parallax-box .parallax')
      const anchor = document.querySelector('#anchor')

      for (const el of parallaxImages) {
        const props = {
          targetElement: anchor,
          // translateY: [0, -15],
          ...Object.entries(el.dataset).reduce((acc, [k, v]) => {
            try {
              acc[k] = JSON.parse(v)
            } catch {
              acc[k] = v
            }

            return acc
          }, {}),
        }

        this.controller.createElement({
          el,
          props,

        })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.parallax-box {
  height: 100vh;
  overflow: hidden;
  position: relative;

  .parallax {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

 #anchor {
  top: 80%;
  position: absolute;
 }
}

.backdrop-video {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.font-merriweather {
  font-family: "Merriweather", serif !important;
}
</style>
