import Errors from './Errors'

export const sleep = ms => new Promise(res => setTimeout(res, ms))

export function removeElement(el) {
  if (typeof el.remove !== 'undefined') {
    el.remove()
  } else {
    el.parentNode.removeChild(el)
  }
}

export function handleErrorString(error) {
  console.error(error)

  const { message } = error
  return Errors[message ?? error] ?? Errors.InternalError
}
