export default {
  DatabaseError: 'Es ist ein unerwarteter Fehler aufgetreten, bitte versuche es später noch einmal oder melde dies bei info@onlinerworld.de',
  InternalError: 'Es ist ein unerwarteter Fehler aufgetreten, bitte versuche es später noch einmal oder melde dies bei info@onlinerworld.de',
  InvalidParams: 'Bitte stelle sicher, dass du alle Daten sorgfältig eingegeben hast.',
  InvalidPassword: 'Ungültiges Passwort.',
  OrderNotFulfilled: 'Diese Bestellung ist noch nicht ganz abgeschlossen, bitte versuche es später noch einmal.',
  OrderNotReady: 'Die Dateien befinden sich derzeit in Bearbeitung, bitte versuche es später noch einmal.',
  DownloadLimitReached: 'Du hast die maximale Anzahl von Downloads erreicht.',
  OrderNotFound: 'Diese Bestellung konnte nicht gefunden werden. Überprüfe bitte, ob du die richtigen Daten eingegeben hast.',
  APIError: 'Es ist ein unerwarteter Fehler aufgetreten, bitte versuche es später noch einmal oder melde dies bei info@onlinerworld.de',
}
