import Vue from 'vue'
import plugins from '@/plugins'
import App from './App.vue'
import '@/styles/styles.scss'

const { vuetify, router } = plugins

Vue.config.productionTip = false

new Vue({
  router,
  vuetify,
  mounted() {
    if (process.env.NODE_ENV !== 'production') {
      window.vm = this
    }

    this.$bus.$emit('app.created', this)
  },
  render: h => h(App),
}).$mount('#app')
