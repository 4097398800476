// Files in this directory are automatically loaded and stored in an object
// which can be deconstructed to pass them to a Vue instance

const req = require.context('./', false, /^(?!.*index\.js)((.*\.js)[^.]*)$/)
const files = {}

req.keys().forEach(fileName => {
  const module = req(fileName)
  const moduleName = fileName.split('/').pop().replace(/\.\w+$/, '')

  files[moduleName] = module.default || module
})

export default files
